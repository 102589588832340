@import 'sanitize';

$dark-text: #111;
$light-text: #464646;
$max-width: 700px;

body {
    font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: $dark-text;
    margin: 3em 1.25em;
    line-height: 1.4;

    @media (prefers-color-scheme: dark) {
        background: #1a1a1a;
        color: #fff;

        a,
        a:hover,
        a:active,
        a:focus,
        a:visited {
            color: #fff;
        }
    }
}

h1, h2, h3, h4 {
    letter-spacing: -0.015em;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    line-height: 1;
}

img {
    max-width: 100%;
}

a,
a:hover,
a:active,
a:focus,
a:visited {
    color: $dark-text;
}

.site-title {
    max-width: $max-width;
    margin: 0 auto 1.25em;
    color: $dark-text;
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 24px;

    a {
        text-decoration: none;
    }
}

.project {
    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .project-title {
        margin: 0;
    }

    .project-subtitle {
        margin-top: 0em;
    }
}

.page-content {
    margin: 0 auto;

    * {
        max-width: $max-width;
        margin-left: auto;
        margin-right: auto;
    }

    img {
        max-width: 100%;

        @media only screen and (min-width: 768px) {
            width: 768px;
            max-width: none;
            margin-left: calc((100% - 768px)/2);
        }
    }

    .blog-titleBlock {
        margin-bottom: 3em;
    }

    .date {
        font-size: 14px;
        text-transform: uppercase;
    }
}

.blogList {
    margin-top: 3em;
}

.blogPreview {
    margin-bottom: 2em;

    a {
        text-decoration: none;
        color: $dark-text;
    }

    .blogPreview-title {
        margin: 0;
        line-height: 1em;
    }

    .blogPreview-date {
        font-weight: normal;
        margin: 0;
        margin-top: 0.5em;
        text-transform: uppercase;
        font-size: 12px;
    }

}

.embed-wrapper {
    width: $max-width;
    max-width: 100%;
    margin: 0 auto;
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.homeTitle {
    text-align: center;
}

.writingPreview {
    margin: 2em 0;
}

.writingPreview-title,
.writingPreview-context,
.writingPreview-date {
    margin: 0;
    line-height: 1em;

    a {
        text-decoration: none;
    }
}

.writingPreview-context,
.writingPreview-date {
    margin-top: 0.5em;
    font-weight: normal;
    font-size: 14px;
}